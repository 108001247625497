import mainImg from"./assets/blogs/main.jpg";


const Images = ()=>{
    return(
        <div className="imgs">
            <img src={mainImg} alt="Image 1" />
        </div>
    );
}


export default Images;